import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@/views/sitio/Index.vue')
    },
    {
        path: '/curso/:slug',
        name: 'Cursos',
        component: () => import('@/views/sitio/FichaPago.vue')
    },
    {
        path: '/ficha/:token',
        name: 'Ficha de Pago',
        component: () => import('@/views/sitio/FichaPagoPublica.vue')
    },
    {
        path: "/:catchAll(.*)",
        component: () => import('@/views/P404.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

/*
router.beforeEach((to, from, next) => {
    if(to.meta.auth && (!user.value || token.value === undefined)) {
        next({name: 'Login', replace: true});
    } else if (to.name === 'Login' && user.value) {
        next({name: 'Resumen', replace: true});
    } else {
        next();
    }
});
*/

export default router;