<template>
  <Loading :state="loading"></Loading>
  <router-view></router-view>
</template>
<script>
import Loading from "@/components/Loading";
import {reactive, provide, defineComponent} from "vue";
export default defineComponent({
  components: {Loading},
  setup(){

    const loading = reactive({
      active: false,
      message: 'Cargando...'
    });

    provide('loading', loading);

    return { loading }
  }
});
</script>
<style src="@/assets/css/global.css">

</style>