import { createApp } from 'vue';
import App from '@/App.vue'
import router from "@/router";
import filters from "@/filters/filters";

import 'animate.css/animate.min.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import {OP_MERCHANT_ID, OP_PUBLIC_API_KEY, OP_SANDBOX_MODE} from "@/providers/config";
import Maska from "maska";

// @ts-ignore
const app = createApp(App);

//Filters
app.config.globalProperties.$filters = filters;

//OpenPay Config
declare var OpenPay : any;
OpenPay.setId(OP_MERCHANT_ID);
OpenPay.setApiKey(OP_PUBLIC_API_KEY);
OpenPay.setSandboxMode(OP_SANDBOX_MODE);

//Mascaras
app.use(Maska);

//Router
app.use(router);

//Mount
app.mount('#app');