import initials from './initials';
import iniciales from './iniciales';
import money from "./money";
import whatsapp from "./whatsapp";

export default {
  initials,
  iniciales,
  money,
  whatsapp
};