export const API_URL = 'https://chio-moreno.com/wp-json/api/';
export const APP_URL = 'https://cursos.chio-moreno.com';
export const APP_PREFIX = 'chio_moreno';

const COMISION_VARIABLE = 0.029;
const COMISION_FIJA = 2.5;
export const IVA = 0.16;

export const COMISION_PAGO = {
    meses: {
        1: {
            label: "2.5MXN + 2.9%",
            fija: COMISION_FIJA,
            variable: COMISION_VARIABLE
        },
        3: {
            label: "2.5MXN + 2.9% + 4.8%",
            fija: COMISION_FIJA,
            variable: COMISION_VARIABLE + 0.048
        },
        6: {
            label: "2.5MXN + 2.9% + 7.8%",
            fija: COMISION_FIJA,
            variable: COMISION_VARIABLE + 0.078
        },
        9: {
            label: "2.5MXN + 2.9% + 10.8%",
            fija: COMISION_FIJA,
            variable: COMISION_VARIABLE + 0.108
        },
        12: {
            label: "2.5MXN + 2.9% + 13.8%",
            fija: COMISION_FIJA,
            variable: COMISION_VARIABLE + 0.138
        },
    }
};
export const OP_MERCHANT_ID = 'mgrzxwdh4vphetofca9n';
export const OP_PUBLIC_API_KEY = 'pk_408d19b87587492197a36d738c576a29';
export const OP_SANDBOX_MODE = false;